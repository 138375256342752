<template>
  <div class="metas">
    <div class="col-md-12 box">
      <div class="row align-items-center h-100">
        <div class="col-md-4">
          <VueSvgGauge
            :start-angle="-180"
            :end-angle="140"
            :value="aroundMath(item.score)"
            :separator-step="25"
            :separatorThickness="7"
            :min="0"
            baseColor="#E3E3E3"
            :max="100"
            :scale-interval="0"
            :gauge-color="scoreColor(aroundMath(item.score))"
            :transition-duration="2500"
          >
            <div class="inner-text h-100 d-flex align-items-center justify-content-center">
              <p v-if="porceto === 'Percent' " key="porcento" class="mb-0">
                <span>{{aroundMath(item.score)}}</span>
                <small>%</small> 
              </p>
              <p v-else-if="porceto === 'money' " key="reais" class="mb-0" style="font-size: 30px;">
                <!-- <small>R$</small>  -->
                <span>{{valueConvert(item.overallSold) }}</span>
              </p>
            </div>
          </VueSvgGauge>
        </div>
        <!-- :gauge-color="colores" -->
        <div class="col-md-8">
          <h4 class="meta_h4 mb-0">{{item.categoryName}}</h4>
          <p class="meta_p mb-0">
            Você atingiu
            <strong>{{aroundMath(item.score)}}%</strong>
            da meta. 
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", 'porceto'],
  data() {
    return {};
  },
  mounted() {},
  watch: {
    item: function() {}
  },
  methods: {}
};
</script>

<style scoped>
small {
  font-size: 20px;
}
.box {
  width: 100%;
  height: 110px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
}
h4 {
  color: #2474b2;
}
.meta_h4 {
  font-size: 10px;
}
.meta_p {
  font-size: 10px;
  color: #868686;
}
.inner-text {
  width: 100%;
}
.inner-text p {
  font-size: 50px;
  color: #2474b2;
  font-weight: bold;
}
</style>