<template>
	<div class="buttonsToggle">
		<button :class="activo ==='Percent' ? 'Percent activo' : 'Percent'" @click="$emit('toggleConvert','Percent')">
			<img src="@/assets/image/metas/porcentagem.svg" alt="real">
		</button>
		<button :class="activo ==='money' ? 'money activo' : 'money'" @click="$emit('toggleConvert','money')" >
			<img src="@/assets/image/metas/real.svg" alt="real">
		</button>
	</div>
</template>

<script>
export default {
  props: ['activo']
}
</script>

<style scoped>
.buttonsToggle button{
  height: 40px;
  width: 40px;
}
.money, .Percent {
  border: none;
  color: #fff;
  outline: none;
  background: #3F88C1;
}

.money {
  /* box-shadow: 2px 3px 9px 0px #000; */
  border-radius: 0 6px 6px 0;
	}

.Percent {
  border-radius: 6px 0 0 6px;
  /* box-shadow: 2px 3px 9px 0px #000; */
}

.activo {
	padding: 2px;
	border: 2px #3F88C1 solid;
  background: #1F659C;
}
</style>